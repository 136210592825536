<template>
    <div class="page">
        <div class="head">
            <span>车辆设置</span>
        </div>
        <div class="content">
            <div style="width: 100%;background-color: #fff;padding: 20px;">
                <div style="float: left;"><Button type="primary" @click="openAddModal">添加车辆</Button></div>
                <div style="margin-top: 50px;">
                    <Table :columns="columnsTable" :data="dataTable"  border>
                        <template slot-scope="{ index }" slot="id">
                            <div>{{ index + 1 }}</div>
                        </template>
                        <template slot-scope="{ row, index }" slot="type">
                            <span class="infoModal_text" @click="openEditModal(row)">修改</span>
                            <span class="infoModal_text" @click="instance(row.id)">删除</span>
                        </template>
                    </Table>
                    <Page class="Page" :page-size="storePrams.limit" :current="storePrams.page" :total="total" show-elevator show-total @on-change="pageChange" border/>
                </div>
            </div>
        </div>
        <!-- 添加弹框 -->
        <Modal v-model="addModal" :mask-closable="false">
            <div slot="header">
                <span class="title_modal">{{title}}</span>
            </div>
            <div style="text-align:center">
                <span style="margin-right: 20px;">车辆</span>
                <Input placeholder="请输入车辆名称" v-model='formData.name' style="width: 300px"></Input>
            </div>
            <div slot="footer">
                <Button @click="addModal = false">取消</Button>
                <Button type="primary" @click="goSubmit()">保存</Button>
            </div>
        </Modal>
    </div>
</template>

<script>
import { carList,delCar,createCar } from "@/api/index";
    export default {
        data() {
            return {
                title:'',
                //table
                columnsTable: [
                    {title: '#',slot: "id",align:'center'},
                    {title: '车辆',key: 'name',align:'center'},
                    {title: '操作',slot: 'type',align:'center'},
                ],
                formData: {
                    id: '',
                    name: '',
                },
                dataTable: [
                ],
                //page
                total: 0,
                storePrams: {
                    page: 1,
                    limit: 30,
                },
                addModal: false,    //添加弹框开关
                ModifyModal: false, //修改弹框开关
            }
        },
        created(){
            this.carList()
        },
        methods: {
            // 车辆列表
            carList(){
                carList(this.storePrams).then(res=>{
                    this.total = res.data.total
                    this.dataTable = res.data.data
                })
            },
            //添加弹框
            openAddModal(){
                this.formData.id = ''
                this.formData.name = ''
                this.addModal = true
                this.title = '添加车辆'
            },
            openEditModal(e){
                this.formData.id = e.id
                this.formData.name = e.name
                this.addModal = true
                this.title = '修改车辆'
            },
            goSubmit(){
                if(!this.formData.name){
                    this.$Message.error('请输入车辆名称');
                    return
                }
                createCar(this.formData).then(res=>{
                    this.$Message.success(res.msg)
                    this.carList()
                    this.addModal = false
                }).catch(err=>{
                    this.$Message.error(err.msg)
                })
            },
            pageChange(index) {
                this.storePrams.page = index;
                this.carList()
            },
            //删除操作
            instance (e) {
                let that = this
                this.$Modal.confirm({
                    title: '警告提示',
                    content: '<p>是否确认删除车辆信息</p>',
                    onOk: () => {
                        delCar({id:e}).then(res=>{
                            this.$Message.success(res.msg)
                            this.carList()
                            this.addModal = false
                        }).catch(err=>{
                            this.$Message.error(err.msg)
                        })                        
                    },
                });
            }
        },
    }
</script>

<style lang="scss" scoped>
.title_modal{
    width: 39px;
    height: 18px;
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #000000;
}
.page{
    background-color: #F5F9FA;
    height: 100%;
}
.head{
    width: 100%;
    height: 70px;
    background-color: #fff;
    margin-bottom: 20px;
    span{
        width: 64px;
        height: 16px;
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #333333;
        float: left;
        margin: 20px 0 0 20px;
    }
}
.content{
    width: 100%;
    padding: 0 20px;
    border-radius: 10px;
    .Page{
        background-color: #fff;
        margin: 20px 0;
        text-align: right;
    }
}
.infoModal_text{
    width: 28px;
    height: 15px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #279EFB;
    line-height: 50px;
    margin: 20px;
    cursor: pointer;
}
</style>